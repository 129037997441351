/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.container {
  width: 100%;
  max-width: 80%;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Navigation Styles */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

nav ul li {
  float: left;
}

nav ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

nav ul li a:hover {
  background-color: #111;
}

/* Winner List Styles */
.winner-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.winner-list li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.winner-list li:last-child {
  border-bottom: none;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .container {
    max-width: 90%;
    padding: 15px;
  }

  th, td {
    padding: 8px;
  }

  nav ul li a {
    padding: 12px 14px;
  }

  .winner-list li {
    padding: 8px 0;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 95%;
    padding: 10px;
  }

  th, td {
    padding: 6px;
    font-size: 14px;
  }

  nav ul li a {
    padding: 10px 12px;
  }

  .winner-list li {
    padding: 6px 0;
  }
  
  nav ul li {
    float: none;
  }

  nav ul {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 100%;
    padding: 5px;
  }

  th, td {
    padding: 4px;
    font-size: 12px;
  }

  nav ul li a {
    padding: 8px 10px;
  }

  .winner-list li {
    padding: 4px 0;
  }
}
