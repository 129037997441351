.container {
  display: block;
  width: 100%;
  max-width: 90%;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: Left;
}

.derby-table {
  width: 100%;
  border-collapse: collapse;
}

.derby-table th,
.derby-table td {
  border: 1px solid #ddd;
  padding: 8px;
  vertical-align: top;
}

.derby-table th {
  background-color: #f2f2f2;
  text-align: center;
  font-weight: bold;
}

.player-info {
  display: flex;
  flex-direction: column;
}

.player-name {
  align-self: flex-start;
  font-weight: bold;
}

.team-name {
  align-self: flex-end;
}

.hr-count {
  align-self: center;
}

.injured {
  background-color: #ffcccc; /* Light red background */
}

