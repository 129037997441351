/* src/components/Footer.css */
.footer {
    text-align: center;
    padding: 10px;
    background-color: #f1f1f1;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  