.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .images-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .memorial-image {
    max-width: 48%;
    height: auto;
    border-radius: 8px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  